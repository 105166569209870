import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';

export interface ModelError {
  code: string;
  reason: string;
}

const isApiErrorModel = (error: unknown): error is { code: string; reason: string } =>
  !!error && typeof error === 'object' && 'code' in error && 'reason' in error;

export const apiErrorInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req).pipe(
    catchError((response: HttpErrorResponse) => {
      if (isApiErrorModel(response.error)) {
        return throwError(() => response.error);
      } else {
        const error: ModelError = {
          code: response.status.toString(),
          reason: response.error.reason
        };
        return throwError(() => error);
      }
    })
  );
};
