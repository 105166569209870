import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DATE_PIPE_DEFAULT_OPTIONS, DatePipeConfig } from '@angular/common';

@Pipe({
  name: 'timeAgo',
  pure: false,
  standalone: true
})
export class TimeAgoPipe implements PipeTransform {


  transform(value: Date, timeZone = 'Europe/Berlin'): string {
    const now = new Date();

    const dateFormat = new Intl.DateTimeFormat('en-US', { timeZone });
    const nowInTimeZone = new Date(dateFormat.format(now));
    const valueInTimeZone = new Date(dateFormat.format(new Date(value)));

    const difference = nowInTimeZone.getTime() - valueInTimeZone.getTime();

    if (difference < 60000) {
      return 'just now';
    } else if (difference < 3600000) {
      const minutes = Math.floor(difference / 60000);
      return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else if (difference < 86400000) {
      const hours = Math.floor(difference / 3600000);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(difference / 86400000);
      return `${days} day${days > 1 ? 's' : ''} ago`;
    }
  }
}
