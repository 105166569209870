<div class='min-h-screen flex relative lg:static surface-ground'>
  <div id='app-sidebar-1'
       class='surface-section h-screen hidden lg:block flex-shrink-0 absolute lg:sticky left-0 top-0 z-2 border-right-1 surface-border select-none'
       style='width:280px'>
    <div class='flex flex-column h-full'>
      <div class='flex align-items-center px-5 flex-shrink-0' style='height: 60px;'><img
        src='https://integratedbiometrics.com/wp-content/uploads/2020/03/clear-company-logo-920x296.png' alt='Image'
        height='40'>
      </div>

      <div class='overflow-y-auto'>
        <ng-container [ngTemplateOutlet]='navigationTemplate' />
      </div>

      <div class='mt-auto'>
        <hr class='mb-3 mx-3 border-top-1 border-none surface-border'>
        <a pRipple
           class='m-3 flex align-items-center cursor-pointer p-3 border-round text-700 hover:surface-100 transition-duration-150 transition-colors'>
          <p-avatar
            [label]='getNameInitials(fullName!)'
            class='mr-2'
            [style]="{ width: '28px', height: '28px', 'background-color': '#dee9fc', color: '#1a2551' }" />
          <span
            class='font-medium'>{{ fullName }}</span><span class='p-ink'></span>
        </a>
      </div>
    </div>
  </div>

  <div class='min-h-screen flex flex-column relative flex-auto'>
    <div
      class='flex justify-content-between align-items-center px-5 surface-0 border-bottom-1 surface-border relative lg:static'
      style='height: 60px;'>
      <div class='flex'>

        <a pRipple pStyleClass='#app-sidebar-1' enterClass='hidden' enterActiveClass='fadeinleft'
           leaveToClass='hidden' leaveActiveClass='fadeoutleft'
           hideOnOutsideClick='true'
           class='cursor-pointer inline-flex align-items-center justify-content-center lg:hidden text-700 mr-3'><i
          class='pi pi-bars text-4xl'></i><span class='p-ink'
                                                style='height: 45px; width: 45px; top: -9.5px; left: 0.5px;'></span>
        </a>

        <span class='p-input-icon-left'>
          <i class='pi pi-search'></i>
          <input type='text' pInputText placeholder='Search'
                 class='border-none w-10rem sm:w-20rem'>
        </span>
      </div>
      <a pRipple pStyleClass='@next' enterFromClass='hidden' enterActiveClass='fadein' leaveToClass='hidden'
         leaveActiveClass='fadeout' class='p-ripple p-element cursor-pointer block lg:hidden text-700'>
        <i class='pi pi-ellipsis-v text-2xl'></i><span class='p-ink'></span>
      </a>
      <ul
        class='list-none p-0 m-0 hidden lg:flex lg:align-items-center select-none lg:flex-row surface-section border-1 lg:border-none surface-border right-0 top-100 z-1 shadow-2 lg:shadow-none absolute lg:static'>

        <ng-container [ngTemplateOutlet]="userTemplate" />


        <li (click)='userMenuPanel.toggle($event)' class='border-top-1 surface-border lg:border-top-none'>
          <a pRipple
             class='p-ripple p-element flex p-3 lg:px-3 lg:py-2 align-items-center hover:surface-100 font-medium border-round cursor-pointer transition-duration-150 transition-colors'>
            <p-avatar
              [label]='getNameInitials(fullName!)'
              class='mr-3 lg:mr-0'
              [style]="{ width: '32px', height: '32px', 'background-color': '#dee9fc', color: '#1a2551' }" />

            <p-overlayPanel class='p-0' #userMenuPanel>
              <p-menu [model]='userMenuItems'></p-menu>
            </p-overlayPanel>
            <span class='p-ink'></span>
          </a>
        </li>
      </ul>
    </div>

    @if (goBackToButtonUrl) {
      <div class='card pl-5 pt-4'>
        <a routerLink='/' class='p-button p-button-secondary p-button-outlined font-bold'>
          Back to previous
        </a>
      </div>
    }

    <div class='p-5 flex flex-column flex-auto overflow-auto page-content'>
      <div class='border-2 surface-border border-dashed border-round surface-section flex-auto'>
        <router-outlet>
          <p-toast />
        </router-outlet>
      </div>
    </div>
  </div>
</div>
